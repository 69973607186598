.c-featured-items__wrapper {
    position: relative;
    background: url("../../svg/forms/wave-features-bottom-sm.svg") no-repeat center / cover;
    z-index: 1;
    padding: 100px 0 120px;
    color: white;
    margin-top: -65px;
}

.c-featured-items__wrapper::after {
    content: '';
    background: url("../../svg/forms/wave-features-top-sm.svg") no-repeat center / cover;
    position: absolute;
    top: 8px;
    bottom: 20px;
    left: 0;
    width: 100%;
    z-index: -1;
}

@media screen and (min-width: 768px) {
    .c-featured-items__wrapper {
        background-image: url("../../svg/forms/wave-features-bottom.svg");
    }

    .c-featured-items__wrapper::after {
        background-image: url("../../svg/forms/wave-features-top.svg");
    }
}

@media screen and (min-width: 1440px) {
    .c-featured-items__wrapper {
        padding: 9.5vw 0;
    }
}
